import React, { useEffect, useState } from 'react';

import { Filter } from 'leeloo-react/component/filter/Filter';
import { DatePickerField } from 'leeloo-react/component/form/field/DatePickerField';
import { InputField } from 'leeloo-react/component/form/field/InputField';
import { useDataSource } from 'leeloo-react/component/hook/useDataSource';
import { ResourceDataProviderEnum } from '../../../enum/ResourceDataProviderEnum';
import { UserDto } from '../../../model/dto/UserDto';
import { Icon } from 'leeloo-react/component/display/Icon';
import moment from 'moment';
import { ProfileDownload } from './ProfileDownload';
import { useSelector } from 'react-redux';
import { StateType } from '../../../model/store/StateType';

type Props = {
    user?:UserDto|null,
}

export default function ProfileAttestations({ user }: Props) {
  const [minEndDate, setMinEndDate] = useState();
  const [filtersOpened, setFiltersOpened] = useState(false);
  const config = useSelector((c: StateType) => c.config);

  const dsRows = useDataSource<any>({
    resource: ResourceDataProviderEnum.activityHistory,
    noLoadAfterInit: true,
  });

  useEffect(() => {
    if (user?.id != null) {
      dsRows.setParams({ id: user.id });
    }
  }, [user?.id]);

  return (
    <>
      <div className="d-flex align-items-center gap-2 justify-content-between mb-4">
        <h2 className="plus mb-0">Attestations</h2>
        <button className={(filtersOpened) ? "btn-toggle-filters opened" : "btn-toggle-filters"} onClick={() => setFiltersOpened(!filtersOpened)}>
          <i className="uil uil-filter" />
          <i className="uil uil-times" />
          Filtres
        </button>
      </div>

      <Filter dataSource={dsRows}>
        <div className={(filtersOpened) ? "attestation-filters opened" : "attestation-filters"}>
          <InputField name="search" label="Rechercher" />
          <DatePickerField label="Période début" name="startDate" onChangeValue={(v) => setMinEndDate(v)} />
          <DatePickerField label="Période fin" name="endDate" dateMin={minEndDate} />
        </div>
      </Filter>

      <div className={(filtersOpened) ? "attestation-count opened" : "attestation-count"}>
        {dsRows.dataList.length} résultats
      </div>

      {dsRows.dataList.map((row) => {

        // Type
        let typeIcon = '';
        if (row.type === 'elearnings') {
          typeIcon = 'desktop';
        } else if (row.type === 'trainings') {
          typeIcon = 'book-alt';
        } else if (row.type === 'webinars') {
          typeIcon = 'video';
        }

        // Validation
        let validationIcon = '';
        if (row.passed) {
          validationIcon = 'uil uil-check-circle icon-validation text-success';
        } else {
          validationIcon = 'uil uil-times-circle icon-validation text-danger';
        }

        return <>
          <div className="attestation-line">
            <div className="title">{ row.title }</div>
            <div className="type"><Icon name={`uil uil-${typeIcon}`} /></div>
            <div>
              <div className="label">Date de début</div>
              <div className="value"><strong>{row.startDate && moment(row.startDate).format('DD-MM-YYYY')}</strong></div>
            </div>
            <div>
              <div className="label">Date de fin</div>
              <div className="value"><strong>{row.endDate && moment(row.endDate).format('DD-MM-YYYY')}</strong></div>
            </div>
            <div>
              <div className="label">Taux de réussite</div>
              <div className="value"><strong>{(row.score) ? row.score + ' %' : ''}</strong></div>
            </div>
            <div>
              <div className="label">Validation</div>
              <div className="value">
                <span className={validationIcon} />
              </div>
            </div>
            <div>
              {row.convention && <ProfileDownload
                title="Convention"
                resource={ResourceDataProviderEnum.resourceConventionFileDownload}
                nameFileFieldLabel="title"
                nameFile="Convention"
                nameExt="pdf"
                row={row}
                disabled={ config.demo }
              />}
            </div>
            <div>
              {row.attestation && <ProfileDownload
                title="Attestation"
                resource={ResourceDataProviderEnum.resourceCertificateFileDownload}
                secondResource={ResourceDataProviderEnum.resourceCertificateTrainingFileDownload}
                params={{ contact: user?.id }}
                nameFileFieldLabel="title"
                nameFile="Attestation"
                nameExt="pdf"
                row={row}
                disabled={ config.demo }
              />}
            </div>
          </div>
        </>
      })}
    </>
  );
}

ProfileAttestations.defaultProps = {
  user: null,
};
