import { ELearningDto } from '../../model/dto/ELearningDto';
import React, { ReactElement, useState } from 'react';
import { useRouter } from 'leeloo-react/component/hook/useRouter';
import { useSelector } from 'react-redux';
import { StateType } from '../../model/store/StateType';
import { OnSiteDto } from '../../model/dto/OnSiteDto';
import PopupDemo from '../popup/PopupDemo';

export function DropdownItem(props: {formation:ELearningDto | OnSiteDto}): ReactElement {

  const [showMessageModal, setShowMessageModal] = useState(false);
  const { goTo } = useRouter();
  const config = useSelector((s: StateType) => s.config);

  const checkValidity = (formation : ELearningDto | OnSiteDto) => {
    let elearning : boolean = ('intitule' in formation);
    if ( !elearning || config.accessELearning) {
      if(!formation?.demo && config.demo){
        onShowMessage();
      }
      else {
        goTo(`/${elearning ? 'detailformations' : 'detailformation'}/${props.formation.id}`);
      }
    }
  };

  const getLabel = (formation : ELearningDto | OnSiteDto) => {
    return ('intitule' in formation) ? formation.intitule : formation.title;
  }

  const onShowMessage = () => {
    setShowMessageModal(true);
  }

  const onHideMessage = () => {
    setShowMessageModal(false);
  }

  return (
    <>
      <a
        href="#"
        className={(!props.formation?.demo && config.demo) ? "disabled-demo" : ""}
        onClick={()=>checkValidity(props.formation)}
      >
        {getLabel(props.formation)}
      </a>
      <PopupDemo
        show={showMessageModal}
        onHide={onHideMessage}
        type="module"
      />
    </>
  );

}
