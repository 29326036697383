import React, { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { StateType } from '../../model/store/StateType';
import PopupDemo from '../popup/PopupDemo';

type RatingModalFooterProps = {
    disabled: boolean,

    handleConfirm:() => void,
}

/**
 * Bouton de confirmation pour customiser la modale de notation de formation
 * En mode demo, le bouton affiche juste une modale d'information
 */
export function RatingModalFooter(props: RatingModalFooterProps): ReactElement {
    const config = useSelector((s: StateType) => s.config);
    const [showMessageModal, setShowMessageModal] = useState(false);

    const onShowMessage = () => {
        setShowMessageModal(true);
    }

    const onHideMessage = () => {
        setShowMessageModal(false);
    }

    return (
        <>
            <button
                type="button"
                className={"btn btn-primary " + (config.demo ? "disabled-demo" : "") }
                disabled={!config.demo && props.disabled}
                onClick={() => (config.demo ? onShowMessage() : props.handleConfirm()) }
            >
                Confirmer
            </button>
            <PopupDemo
                show={showMessageModal}
                onHide={onHideMessage}
                type="link"
            >
            </PopupDemo>
        </>
    );
}
