import React, { useEffect, useState } from 'react';

import axios from 'axios';
import dateFormat from 'dateformat';

import Hn from '../../components/common/Hn';
import Jumbotron from '../../components/jumbotron/Jumbotron';
import { BaseContainer } from '../../components/layout/BaseContainer';

import './email.css';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';

import { OnSiteMostViewed } from '../../components/pages/onSite/OnSiteMostViewed';
import {SelectField} from "leeloo-react/component/form/field/SelectField";

export default function Email() {
  const [selectedItem, setSelectedItem] = useState();
  const [trainingMostSorted, setTrainingMostSorted] = useState([]);
  const [emails, setEmails] = useState([]);
  const [nbmsg, setNb] = useState();
  const IMG_URL = process.env.REACT_APP_PIC_FACETRAINING_PATH;
  const uri = process.env.REACT_APP_BASE_URL;
  const { authData } = useAuthProvider();
  axios.defaults.headers.common = { Authorization: `Bearer ${authData.token}` };

  function LineEmail({ data, setSelectedItem }) {
    return (
      <tr onClick={() => {
        setSelectedItem(data);
        luEmail(data.id);
      }}
      >
        <td className={data?.view ? '' : 'fw-bold'}>{data?.sender}</td>
        <td className={data?.view ? '' : 'fw-bold'}>{dateFormat(data?.createdAt, 'dd/mm/yyyy')}</td>
        <td className={data?.view ? '' : 'fw-bold'}>{data?.object}</td>
      </tr>
    );
  }

  // récupérer les documents du user en question
  function getEmails() {
    axios
      .get(`${uri}/document/messages`)
      .then((result) => {
        setEmails(result?.data);
        setSelectedItem(result?.data[0]);
        luEmail(result?.data[0].id);
        setNb(result?.data.filter((el) => el.view === false).length);
        //  setNb(nb.length)
      })
      .catch((err) => {
        console.log('err  attestations', err);
      });
  }

  // récupérer(notification) le nombre des messages lus
  function luEmail(id) {
    axios
      .post(`${uri}/document/notify/${id}`)
      .then((result) => {
      })
      .catch((err) => {
        console.log('err lu email', err);
      });
  }

  useEffect(() => {
    getEmails();
  }, []);

  // récupérer les TOP 10 selon le nombre d’inscrit aux sessions
  function getTrainingMost() {
    axios
      .get(`${uri}/training`)
      .then((result) => {
        const trainingMostSortedD = result?.data?.data;

        trainingMostSortedD.sort((a, b) => b.sessions.length - a.sessions.length);

        setTrainingMostSorted(trainingMostSortedD);
      })
      .catch((err) => {
        console.log('err training', err);
      });
  }

  useEffect(() => {
    getTrainingMost();
  }, []);

  return (
    <BaseContainer>
      <div className="email">

        <div className="d-flex flex-column flex-md-row justify-content-between gap-4 align-items-md-end mb-4 mb-lg-5">
          <div>
            <Hn title="Mes documents" level="h1" plus={true} />
            <div className="fs-5 text-uppercase">
              {nbmsg}{' '}message(s) non lu(s)
            </div>
          </div>
          <div className="col-12 col-md-5 d-xl-none">
            { selectedItem && <SelectField
              isClearable={false}
              value={selectedItem}
              optionsList={emails.map( (item) => {
                return {
                  value:item,
                  label : dateFormat(item?.createdAt, 'dd/mm/yyyy') + ' - ' + item.object
                }
              })}
              onChangeValue={setSelectedItem}
            />
            }
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-xl-6">
            <Jumbotron data={selectedItem} type={selectedItem?.type} />
          </div>
          <div className="d-none d-xl-block col-xl-6">
            <table className="table table-dark">
              <thead>
                <tr className="tr">
                  <th>Expéditeur</th>
                  <th>Date</th>
                  <th>Objet</th>
                </tr>
              </thead>
              <tbody>
                {emails?.map((email, i) => (
                  <LineEmail data={email} setSelectedItem={setSelectedItem} key={i} />
                ))}
              </tbody>
            </table>

          </div>
        </div>
        <OnSiteMostViewed />
      </div>
    </BaseContainer>
  );
}
