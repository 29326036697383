export enum ResourceDataProviderEnum {
    usersValidateTerms='participant/cgu',
    agency='agence',
    // E-learning
    eLearning='elearning',
    eLearningDuplicate='elearning/duplicate',
    eLearningExport='elearning/export',
    eLearningImport='elearning/import',
    eLearningMyProfile='elearning/my-profile',
    eLearningMostViewed='elearning/get-most-viewed',
    eLearningScore='watershed/elearning/score',
    eLearningStarted='elearning/get-started',
    eLearningUpdateStatus='elearning/edit/status',
    eLearningUserScores='watershed/elearning/:id/:contact',
    eLearningRating='elearning/rating',
    eLearningCanRate='elearning/can-rate',
    // E-learning listing des participants
    learner='participant',
    learnerExportElearning='participant/export/elearnings',
    learnerExportTrainings='participant/export/trainings',
    // Formations présentielles
    training='training',
    trainingDuplicate='training/duplicate',
    trainingExport='training/export',
    trainingImport='training/import',
    trainingMostViewed='training/get-most-viewed',
    trainingUpdateStatus='training/edit/status',
    // Thématiques
    category='category',
    thematic='thematic',
    thematicImport='thematic/import',
    thematicExport='thematic/export',
    thematicDuplicate='thematic/duplicate',
    // Sessions de formations
    session='session',
    sessionDuplicate='session/duplicate',
    sessionImport='session/import',
    sessionExport='session/export',
    sessionPresence='session/presence',
    sessionParticipants='session/participants',
    sessionUpdateStatus='session/edit/status',
    sessionDownloadAttendance='session/:id/download',
    participant='participant',
    userCollaborators='participant/collaborators',
    userCollaboratorsByAgency='agence/collaborators',
    participantSession='participant/session',
    participantTrainingRequest='participant/collaborators',
    participantWebinar='participant/webinar',
    participantStatistics='participant/stats',
    trainer='former',
    trainerImport='former/import',
    trainerExport='former/export',
    provider='provider',
    providerImport='provider/import',
    providerExport='provider/export',
    trainingRequest='besoins-formation',
    trainingRequestExport='besoins-formation/export',
    trainingRequestValidate='besoins-formation/validate',
    trainingRequestArchive='besoins-formation/archive',
    trainingRequestUnarchive='besoins-formation/unarchive',
    trainingRequestList='besoins-formation/liste',
    trainingRequestStatus='besoins-formation/status',
    regionsList='regions/liste',
    trainingPlace='location',
    trainingPlaceImport='location/import',
    trainingPlaceExport='location/export',
    trainingPlaceDuplicate='location/duplicate',
    skill='skills',
    skillImport='skills/import',
    skillExport='skills/export',
    skillDuplicate='skills/duplicate',
    // utilisateurs
    user='user',
    webinar='webinars',
    webinarAll='webinars/all',
    webinarUpdateStatus='webinars/edit/status',
    webinarImport='webinars/import',
    webinarExport='webinars/export',
    webinarDuplicate='webinars/duplicate',
    webinarPresence='webinars/presence',
    // Documents
    resourceConventionFileDownload='document/convention',
    resourceCertificateFileDownload='document/certificate/:contact',
    resourceCertificateTrainingFileDownload='document/certificateTraining/:contact',
    // Listing des formations réalisés par l'utilisateur
    activityHistory = 'watershed/activity',
    activityHistoryByType = 'watershed/get_user_data/:contact/:type',
    // Kpis
    kpisGlobal='dashboard/global',
    kpisGlobalExport='dashboard/global/export',
    kpisELearnings='dashboard/elearning',
    kpisELearningsExport='dashboard/elearning/export',
    kpisThematics='dashboard/theme',
    kpisThematicsExport='dashboard/theme/export',
    kpisRegions='dashboard/regions',
    kpisRegionsExport='dashboard/regions/export',
    kpisSectors='dashboard/sector',
    kpisSectorsExport='dashboard/sector/export',
    kpisLearners='dashboard/participant',
    kpisLearnersExport='dashboard/participant/export',
    kpisTrained='dashboard/trained',
    kpisTrainedExport='dashboard/trained/export',
    // Manager Kpis
    kpisManagerTrainingThematicsByAgency='dashboard/training/agency/theme',
    kpisManagerTrainingThematicsByAgencyExport='dashboard/training/agency/theme/export',
    kpisManagerTrainingThematicsByCollaborator='dashboard/training/collaborator/theme',
    kpisManagerTrainingThematicsByCollaboratorExport='dashboard/training/collaborator/theme/export',
    kpisManagerELearningThematicsByAgency='dashboard/elearning/agency/theme',
    kpisManagerELearningThematicsByAgencyExport='dashboard/elearning/agency/theme/export',
    kpisManagerELearningThematicsByCollaborator='dashboard/elearning/collaborator/theme',
    kpisManagerELearningThematicsByCollaboratorExport='dashboard/elearning/collaborator/theme/export',
    // Settings
    settings='settings'
}
