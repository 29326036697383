import React, { useEffect, useState } from 'react';

import axios from 'axios';
import dateFormat from 'dateformat';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import { RouterLink } from 'leeloo-react/component/router/RouterLink';
import Modal from 'react-bootstrap/Modal';
import OwlCarousel from 'react-owl-carousel';
import { useNavigate } from 'react-router-dom';

import Hn from '../../components/common/Hn';
import Icon from '../../components/common/Icon';
import { ResourceDataProviderEnum } from '../../enum/ResourceDataProviderEnum';
import { RouteEnum } from '../../enum/RouteEnum';

function ListingWebinaire() {
  const navigate = useNavigate();

  const uri = process.env.REACT_APP_BASE_URL;
  const [webinair, setWebinair] = useState([]);
  const [id, setId] = useState([]);
  const [webinarsTitle, setWebinarsTitle] = useState([]);
  const [showModalInscri, setShowModalInscri] = useState(false);
  const { authData } = useAuthProvider();
  axios.defaults.headers.common = { Authorization: `Bearer ${authData.token}` };

  const options = {
    items: 2,
    responsiveClass: true,
    margin: 27,
    nav: true,
    dots: false,
    responsive: {
      0: {
        items: 1.25,
      },
      400: {
        items: 1.3,
      },
      600: {
        items: 1.8,
      },
      800: {
        items: 2,
      },
      1000: {
        items: 2.7,
      },
      1200: {
        items: 3,
      },
      1300: {
        items: 3.7,
      },
      1600: {
        items: 4,
      },
      1800: {
        items: 5,
      },
      2000: {
        items: 6,
      },
      2200: {
        items: 7,
      },
      2400: {
        items: 7,
      },
      2600: {
        items: 8,
      },
      2800: {
        items: 9,
      },
      3000: {
        items: 10,
      },
      3200: {
        items: 11,
      },
      3400: {
        items: 12,
      },
    },
  };

  const hideModal = () => {
    setShowModalInscri(false);
  };

  function getwebinair() {
    axios
      .get(`${uri}/webinars`)
      .then((result) => {
        setWebinair(result?.data);
      })
      .catch((err) => {
        console.log('err webinar', err);
      });
  }

  function inscrire() {
    const data = {
      idEntity: id,
    };

    axios.post(`${uri}/${ResourceDataProviderEnum.participantWebinar}`, data)
      .then(() => {
        setTimeout(() => {
          navigate(0);
        }, 100);
      })
      .catch((error) => {
        console.log('error', error);
      });
  }

  useEffect(() => {
    getwebinair();
  }, []);

  return (
    <div className="webinars mt-5 pt-3 position-relative">
      {webinair && webinair.length > 0
                && (
                  <div className="py-3">
                    <Hn
                      title="WEBINAIRE A VENIR"
                      level="h3"
                      className="pb-4 pt-4"
                    />
                    <OwlCarousel className="owl-theme" {...options}>
                      {webinair.map((item) => (
                        <div key={item.id}>
                          <div className="pt-3">
                            <h4>
                              {item?.title}
                            </h4>
                            <ul className="list-inline">
                              <li className="list-inline-item">
                                <Icon name="Calendar" data={dateFormat(item?.date, 'dd/mm/yyyy')} />
                              </li>
                              <li className="list-inline-item">
                                <Icon name="Clock" data={item?.hour} />
                              </li>
                              {item?.former && item?.former.length > 0 && item?.former.map((former) => (
                                <li className="list-inline-item" key={former.id}>
                                  <Icon name="profil" data={`${former?.lastName} ${former?.firstName}`} />
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div className="d-flex ">
                            <div className="me-2">
                              {
                                item?.contacts && item?.contacts.some((obj) => (obj.contact.id === authData.id))
                                  ? (
                                    <RouterLink rel="opener" className="btn btn-primary" routeName={RouteEnum.WEBINAR_DETAIL} params={{ id: item.id }}>
                                      Détail
                                    </RouterLink>
                                  )
                                  : (
                                    <>
                                      <button
                                        type="button"
                                        role="button"
                                        title=" S'inscrire"
                                        className="btn btn-primary"
                                        onClick={() => { setId(item.id); setWebinarsTitle(item.title); setShowModalInscri(true); }}
                                      >
                                        S'inscrire
                                      </button>
                                      <RouterLink rel="opener" className="btn btn-outline-primary" routeName={RouteEnum.WEBINAR_DETAIL} params={{ id: item.id }}>
                                        En savoir plus
                                      </RouterLink>
                                    </>
                                  )
                              }
                            </div>
                          </div>
                        </div>
                      )) }
                    </OwlCarousel>
                  </div>
                )}

      <Modal
        show={showModalInscri}
        onHide={() => hideModal()}
        size="lg"
        className="special-width-popup"
      >
        <Modal.Header closeButton>
          <Modal.Title className="px-3 nopad">
            <Hn title="Confirmation d'inscription" level="h3" plus={false} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row px-3 nopad">
            <div className="col-md-12">
              <Hn
                title="Voulez vous confirmez votre inscription au nom de :"
                level="h5"
                plus={false}
                className="label-popup"
              />
              <p className="text-white textBox">
                {`${authData.firstname} ${authData.lastname}`}
              </p>
            </div>
            <div className="pt-2">
              <Hn
                title="Webinaire :"
                level="h5"
                plus={false}
                className="label-popup"
              />
              <p className="text-white textBox">
                {webinarsTitle}
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary float-end" onClick={() => { hideModal(); inscrire(); }}>
            Confirmer
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ListingWebinaire;
