import React, { useEffect } from 'react';
import { useAuthUrl } from '../../../../hooks/useAuthUrl';

/**
 * Propose une preview de la ressource (vidéo) dans une unite de formation
 */
export function VideoPreview(props : {unite : {id: number|undefined, extension: string, duree: string, document: string}}) {
  const {url, setAuthUrl } = useAuthUrl();

  useEffect(() => {
    if(props.unite.id){
      setAuthUrl(process.env.REACT_APP_BASE_URL + '/uploads/unite/' + props.unite.id + '/document');
    }
  }, [props.unite.id])

  return (
    <div className="col-md-3 mt-0 mb-5">
      {!!url && <video
        className="hidden"
        id={`video-${props.unite.id}`}
        width="320"
        height="240"
        controls
      >
        <source
          src={url}
          type={props.unite.extension == 'webm' ? 'video/webm' : 'video/mp4'}
        />
        Your browser does not support the
        video tag.
      </video>}
      <div className="col-md-3">
        <label className="form-label">
          Durée
        </label>
        <input
          id={`duree-${props.unite.id}`}
          type="text"
          className="form-controlinput duree_input"
          value={props.unite.duree}
          readOnly
        />
      </div>
    </div>
  )
}
