import React, {
  useEffect, useState, useRef, useCallback,
} from 'react';

import axios from 'axios';
import { Icon } from 'leeloo-react/component/display/Icon';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import Modal from 'react-bootstrap/Modal';
import Iframe from 'react-iframe';
import ReactPlayer from 'react-player/lazy';
import { useNavigate } from 'react-router-dom';

import { PopupRating } from './PopupRating';
import { Spinner } from '../common/Spinner';
import { useRatingModal } from '../../hooks/useRatingModal';
import { useToast } from 'leeloo-react/component/hook/useToast';

export default function PopupVideo({ play, run = null, handleClose = null }) {
  const nextRef = useRef();
  const videoRef = useRef();
  const audioRef = useRef();
  const [show, setShow] = useState(false);
  const [position, setPosition] = useState(1);
  const [total_document, setTotalDocument] = useState(1);
  const [unite, setUnite] = useState(play?.unite);
  const [elearning, setElearning] = useState(play?.elearning);
  const [chapitre, setChapitre] = useState(play?.chapitre);
  const [scormdirectoryname, setScormdirectoryname] = useState();
  const [scorm_url, setScormUrl] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [playerCurrentTime, setPlayerCurrentTime] = useState(null);
  const [label, setLabel] = useState('Commencer');
  const [start, setDateStart] = useState(new Date());
  const [showLoader, setShowLoader] = useState(false);
  const scormTempsRef = useRef(0);
  const scormScore = useRef(0);
  const scormNbQuestions = useRef(0);
  const { authData } = useAuthProvider();
  axios.defaults.headers.common = { Authorization: `Bearer ${authData.token}` };
  const uri = process.env.REACT_APP_BASE_URL;
  const unite_id = play?.unite?.id;
  const toast = useToast();

  let xtoast = 1;
  let ytoast = 1;
  const navigate = useNavigate();

  useEffect(() => {
    setLabel(play?.label);
    setUnite(play?.unite);
    setElearning(play?.elearning);
    setChapitre(play?.chapitre);
    setTotalDocument(play?.total_document);
    setPosition(play?.position);
    setScormdirectoryname(play?.unite?.scormdirectoryname);
    setVideoUrl(process.env.REACT_APP_PIC_ELEARNING_FILE + play?.unite?.document);
    setScormUrl(`${process.env.REACT_APP_PIC_ELEARNING_SCORM + play?.unite?.scormdirectoryname}/story.html`);

    scormScore.current = 0;
    scormTempsRef.current = 0;
    scormNbQuestions.current = 0;

    if (run) {
      setShow(true);
      setDateStart(new Date());
      run = 'done';
    }
  }, [play]);

  useEffect(() => {
    const handleMessage = (event) => {
      if (show) {
        scormScore.current = event.data?.detail?.score;
        scormNbQuestions.current = event.data?.detail?.nb_questions;

        if (event.data?.detail?.exit === 'oui' && typeof unite !== 'undefined') {
          scormTempsRef.current = 1;
          nextRef.current.click();
        }
      }
    };

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [show]);

  const onVideoStart = useCallback(
    (timeToStart) => {
      videoRef.current.seekTo(timeToStart, 'seconds');
    },
    [videoRef.current],
  );

  const onAudioStart = useCallback(
    (timeToStart) => {
      audioRef.current.seekTo(timeToStart, 'seconds');
    },
    [audioRef.current],
  );

  const saveTimer = async () => {
    const now = new Date();
    const trackingDuration = now.getTime() - start.getTime();
    const passedTime = playerCurrentTime ?? Math.round(trackingDuration / 1000);
    const data = new FormData();
    data.append('unite', unite?.id);
    data.append('duree', passedTime);

    return await axios
      .post(`${uri}/player_resume/new`, data)
      .catch((err) => {
        console.log('err', err);
        toast('Erreur de mise à jour des temps passés sur la formation.', 'error');
      });
  };

  const saveScore = async () => {
    const data = new FormData();
    if (scormdirectoryname !== null) {
      if (scormTempsRef.current === 0) {
        return;
      }
      // validation scorm
      data.append('scorm_score', scormScore.current);
      data.append('scorm_nb_questions', scormNbQuestions.current);
    }

    const verb = 'checkin';

    data.append('unite', unite?.id);
    data.append('unite_passed', true);
    data.append('verb', verb);

    return await axios.post(`${uri}/watershed/new`, data)
      .catch((err) => {
        console.log('err', err);
        toast('Erreur de mise à jour des scores sur la formation.', 'error');
      });

  };

  const initWatershed = () => {
    const data = new FormData();
    const verb = 'start';

    data.append('verb', verb);
    data.append('unite', unite_id);

    if(!unite_id) {
      // Gere le cas d'une formation sans unites de formation
      toast.error('Désolé cette formation n\'a pas de contenu');
    }
    else{
      axios
        .post(`${uri}/watershed/new`, data)
        .then((result) => {
          setShow(true);
          setDateStart(new Date());
        })
        .catch((err) => {
          toast.error('Désolé cette formation n\'a pas de contenu');
          console.log('err data', err);
        });
    }
  };

  const handleModalClose = async (withoutSave = null) => {
    setShowLoader(true);
    // Display loader
    if (withoutSave === true) {
      setShowLoader(false);
      setShow(false);
      if (handleClose) {
        handleClose();
      }
      if (run) {
        navigate(`/detailformations/${elearning.id} `);
      }
    } else {
      await Promise.all([
        saveTimer(),
        saveScore(),
      ]).then(() => {
        setShowLoader(false);
        setShow(false);
        if (handleClose) {
          handleClose();
        }
        if (run) {
          navigate(`/detailformations/${elearning.id} `);
        }
      });
    }
  };

  const {ratingModal, hideRatingModal, showRatingModal} = useRatingModal(handleModalClose);

  const handleShow = () => {
    if (play?.label === 'Commencer') {
      initWatershed();
    } else if (play?.label === 'Reprendre') {
      setDateStart(new Date());
      setShow(true);
    }
  };

  const handlePrevClicked = async () => {
    setShowLoader(true);
    await Promise.all([
      saveTimer(),
      saveScore(),
    ]).then(() => {
      loadPrev();
      setShowLoader(false);
    });
  };

  const loadPrev = () => {
    const data = new FormData();

    data.append('unite', unite?.id);

    axios
      .post(`${uri}/watershed/get_prev`, data)
      .then((result) => {
        if (result?.data?.first === true) {
          toast('Désolé Oups! Vous ne pouvez aller plus loin.','error');
        } else {
          setPosition(result?.data?.position);
          setTotalDocument(result?.data?.total_document);
          setUnite(result?.data?.unite);
          setChapitre(result?.data?.chapitre);
          setScormdirectoryname(result?.data?.unite?.scormdirectoryname);
          setVideoUrl(`${process.env.REACT_APP_PIC_ELEARNING_FILE}${result?.data?.unite?.document}`);
          setScormUrl(`${process.env.REACT_APP_PIC_ELEARNING_SCORM}${result?.data?.unite?.scormdirectoryname}/story.html`);
          setPlayerCurrentTime(null);
        }
      })
      .catch((err) => {
        console.log('err data', err);
        toast('Désolé Oups! Vous ne pouvez pas aller plus loin.', 'error');
      });
  };

  const handleNextClicked = async () => {
    setShowLoader(true);
    await Promise.all([
      saveTimer(),
      saveScore(),
    ]).then(() => {
      loadNext();
      setShowLoader(false);
    });
  };

  const loadNext = () => {
    const data = new FormData();
    if (scormdirectoryname !== null) {
      if (scormTempsRef.current === 0) {
        toast('Désolé vous devez finir le cours avant!','error');
        return;
      }
    }

    data.append('unite', unite?.id);

    axios
      .post(`${uri}/watershed/get_next`, data)
      .then((result) => {
        if (result?.data?.elearning_passed) {
          // WTF ????
          if (xtoast === 1 && result?.data?.score >= 100) {
            toast('Vous avez terminé cette formation!', 'success');
            xtoast += 1;
          }
          showRatingModal(elearning);
        }

        setPosition(result?.data?.position);
        setTotalDocument(result?.data?.total_document);
        setUnite(result?.data?.unite);
        setChapitre(result?.data?.chapitre);
        setScormdirectoryname(result?.data?.unite?.scormdirectoryname);
        setVideoUrl(process.env.REACT_APP_PIC_ELEARNING_FILE + result?.data?.unite?.document);
        setScormUrl(`${process.env.REACT_APP_PIC_ELEARNING_SCORM + result?.data?.unite?.scormdirectoryname}/story.html`);
        setPlayerCurrentTime(null);

        if (result?.data?.chapitre_passed) {
          // WTF ????
          if (ytoast === 1) {
            toast('Vous avez terminé ce chapitre!','success');
            ytoast += 1;
          }
        }
      })
      .catch((err) => {
        console.log('err data', err);
        toast('Erreur de sauvegarde des scores sur la formation.', 'error');
      });
  };

  return (
    <>
      <button className="btn btn-primary w-100 pt-3 pb-3 fw-bold text-uppercase" onClick={handleShow}>
        {label}
      </button>
      <Modal
        show={show}
        onHide={() => handleModalClose()}
        className="videomodal"
        backdrop="static"
        fullscreen
      >
        {showLoader && <Spinner />}
        <Modal.Header closeButton>
          <div className="unite-header">
            <div>
              <div className="title">{elearning?.intitule}</div>
              <div className="sub-title">{`${chapitre?.titre} | ${unite?.titre}`}</div>
            </div>
            <div className="navigator">
              {`Document ${position}/${total_document}`}
              <button id="prev" type="button" className="btn btn-outline-primary" onClick={handlePrevClicked}>
                <Icon name="uil uil-angle-left" />
              </button>
              <button ref={nextRef} id="next" type="button" className="btn btn-outline-primary" onClick={handleNextClicked}>
                <Icon name="uil uil-angle-right" />
              </button>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div id="content_player" className="text-center">
            {unite?.extension == 'zip' && (
              <Iframe
                url={scorm_url}
                id="scorm_content"
                display="initial"
                position="relative"
                className="vidIframe"
              />
            )}
            { (unite?.extension == 'mp4' || unite?.extension == 'webm') && (
              <ReactPlayer
                ref={videoRef}
                url={videoUrl}
                playing
                controls
                config={{
                  file: {
                    attributes: {
                      controlsList: 'nodownload',
                    },
                  },
                }}
                onProgress={(progress) => {
                  const timer = progress.playedSeconds !== 0 ? progress.playedSeconds : (parseInt(unite?.resume_time, 10) || 0);
                  setPlayerCurrentTime(timer);
                }}
                onStart={() => {
                  onVideoStart(unite?.resume_time);
                }}
              />
            )}
            {unite?.extension == 'pdf' && (
              <object data={videoUrl} type="application/pdf">
                <p>
                  Erreur de chargement, vous pouvez télécharger le PDF
                  {' '}
                  <a href={videoUrl}>ici</a>
                </p>
              </object>
            )}
            {unite?.extension == 'mp3' && (
              <ReactPlayer
                ref={audioRef}
                url={videoUrl}
                controls
                config={{
                  file: {
                    attributes: {
                      controlsList: 'nodownload',
                    },
                  },
                }}
                onProgress={(progress) => {
                  setPlayerCurrentTime(progress.playedSeconds);
                }}
                onStart={() => {
                  onAudioStart(unite?.resume_time);
                }}
              />
            )}
          </div>
          <PopupRating show={ratingModal} onHide={hideRatingModal} elearning={elearning} />
        </Modal.Body>
      </Modal>
    </>
  );
}
