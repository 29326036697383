import React, { Fragment, useEffect, useState } from 'react';

import axios from 'axios';
import { Interweave } from 'interweave';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import { useRouter } from 'leeloo-react/component/hook/useRouter';
import { RouterLink } from 'leeloo-react/component/router/RouterLink';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';

import Placeholder from '../../assets/placeholder.png';
import Hn from '../../components/common/Hn';

import 'moment/locale/fr';
import { BaseContainer } from '../../components/layout/BaseContainer';
import Popupinscrire from '../../components/popup/Popupinscrire';
import PopupDemande from '../../components/popup/PopupDemande';

import './DetailFormation.css';
import RoleUserEnum from '../../enum/RoleUserEnum';
import { RouteEnum } from '../../enum/RouteEnum';
import PopupVideo from "../../components/popup/PopupVideo";
import Chart2 from "../../components/chartjs/Chart2";
import { useSelector } from 'react-redux';
import PopupDemo from '../../components/popup/PopupDemo';

export default function DetailFormation({
  title, disable, disabled, switchs,
}) {
  const [nbplace, setnbPlace] = useState(0);
  const [session, setSession] = useState([]);
  const [sessionsOpened, setSessionsOpened] = useState(false);
  const [training, setTraining] = useState([]);
  const [options, setOptions] = useState([]);
  const params = useParams();
  const [showInscriptionModal, setShowInscriptionModal] = useState(false);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [dataformation, setDataformation] = useState([]);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const { goBack } = useRouter();
  const { authData, checkAccess } = useAuthProvider();
  const config = useSelector((c) => c.config);

  const canRegister = [
    RoleUserEnum.ROLE_SUPER_ADMIN,
    RoleUserEnum.ROLE_ASSOCIE,
    RoleUserEnum.ROLE_GESTIONNAIRE,
    RoleUserEnum.ROLE_AGENT_IMMOBILIER,
    RoleUserEnum.ROLE_ASSISTANT_COMMERCIAL,
  ];

  const canRequest = [
    RoleUserEnum.ROLE_SUPER_ADMIN,
    RoleUserEnum.ROLE_ASSOCIE,
    RoleUserEnum.ROLE_AGENT_IMMOBILIER,
  ];

  const docTraining = process.env.REACT_APP_PIC_FACETRAINING_FILE;
  const IMG_URL = process.env.REACT_APP_PIC_FACETRAINING_PATH;
  moment.locale('fr');
  axios.defaults.headers.common = { Authorization: `Bearer ${authData.token}` };
  const uri = process.env.REACT_APP_BASE_URL;

  // récupérer les formations présentielles et afficher leurs détails
  function getTrainingDetails() {
    axios
      .get(`${uri}/training/${params.id}`)
      .then((result) => {
        setSession(result?.data.sessions);
        setTraining(result?.data);
        let arrayContact = [];
        result?.data?.sessions.forEach((el) => {
          arrayContact = arrayContact.concat(el.contactSessions);
        });

        const info = {
          id: result?.data.id,
          title: result?.data.title,
          cat: result?.data?.type?.name,
          cost: result?.data.cost,
        };
        setDataformation(info);
        let totplace = 0;
        if (result?.data.sessions?.length > 0) {
          result?.data.sessions?.map((item) => {
            if (parseInt(item?.seat_capacity_max) - parseInt(item?.contactSessions.length) > 0) {
              const rest = parseInt(item?.seat_capacity_max) - parseInt(item?.contactSessions.length);
              totplace = parseInt(totplace) + parseInt(rest);

              setOptions((options) => [
                ...options,
                {
                  value: item.id,
                  label: `${item?.location?.town} - ${(new Date(item?.dateStart))?.toLocaleDateString('fr', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}`,
                },
              ]);
            }
          });
          setnbPlace(totplace);
        }
      })
      .catch((err) => {
        console.log('err training', err);
      });
  }

  const handleCloseInscriptionModal = () => {
    setShowInscriptionModal(false);
    setOptions([]);
    getTrainingDetails();
  };

  const handleCloseRequestModal = () => {
    setShowRequestModal(false);
    setOptions([]);
    getTrainingDetails();
  };

  const onShowMessage = () => {
    setShowMessageModal(true);
  }

  const onHideMessage = () => {
    setShowMessageModal(false);
  }

  const img = training?.image ? IMG_URL + training.image : Placeholder;

  useEffect(() => {
    getTrainingDetails();
  }, [params.id]);

  return (
    <BaseContainer>
      <nav aria-label="breadcrumb">
        <div className="d-none d-md-block">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <RouterLink routeName={RouteEnum.PRESENTIEL}>
                Formations présentielle
              </RouterLink>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {training.title}
            </li>
          </ol>
        </div>
        <span className="look-like-link resetBtn" onClick={() => goBack()}>Retour</span>
      </nav>
      <div className="row mb-4">
        <div className="col-md-9">
          <h1 className="pt-4">{training.title}</h1>
          {training?.associationRoute?.id && (
            <p className="text text-white">
              {'Parcours associé : '}
              <Link to={`/${training.associationRoute.type === 'elearning' ? 'detailformations' : 'detailformation'}/${training.associationRoute.id}`}>
                {training.associationRoute.title}
              </Link>
            </p>
          )}
        </div>
      </div>
      <div className="formation-header">
        <div className="row">
          <div className="col-3 d-none d-xl-block">
            <img src={img} alt="" />
          </div>
          <div className="col-xl-9 col-lg-12">
            <div className="row">
              <div className="d-xl-none col-md-5 col-lg-4 mb-4 mb-md-0">
                <img src={img} alt="" />
              </div>
              <div className="col-md-7 col-lg-8 col-xl-12">
                <h5>Prérequis et public visé</h5>
                {training?.audience && (
                  <div className="simpletext py-1 text-white">
                    <Interweave content={training.audience} />
                  </div>
                )}
              </div>
            </div>
            <hr className="my-4" />
            <div className="row cards-grid">
              <div className="col-6 col-md-3">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Durée</h5>
                    <p className="card-text">
                      {training?.duration}
                      {' '}
                      h
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Tarif HT</h5>
                    <p className="card-text">
                      {`${training?.cost} €`}
                      <br />
                      {training.nonAgencyCost ? '(Hors agence 2025)' : ''}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Sessions</h5>
                    <p className="card-text">
                      {training?.sessions?.length}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Places restantes</h5>
                    <p className="card-text">{nbplace}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="formation-details">
        <div className="col-main">
          {training?.objective && (
            <>
              <h5 className="mb-4">Objectifs de la formation</h5>
              <div className="simpletext text-white mb-5">
                <Interweave content={training.objective} />
              </div>
            </>
          )}
          {(training?.programFile || training?.training_program) && (
            <>
              <div className="d-flex flex-column flex-lg-row gap-2 justify-content-lg-between mb-4">
                <h5 className="mb-4">Programme</h5>
                {(training?.programFile) && (
                  <a href={docTraining + training.programFile} target="_blank" rel="noreferrer">
                    Télécharger le programme
                  </a>
                )}
              </div>
              {training?.training_program && (
                <div className="simpletext text-white mb-5">
                  <Interweave content={training.training_program} />
                </div>
              )}
            </>
          )}
          {training?.comment && (
            <>
              <h5 className="mb-4">Commentaire</h5>
              <div className="simpletext mb-5 text-white">
                <Interweave content={training.comment} />
              </div>
            </>
          )}
        </div>
        <div className="col-aside">

          <div className="d-flex flex-column flex-lg-row gap-3 mb-4">
            {checkAccess(canRegister) && (
              <button
                className={"btn btn-primary flex-grow-1 fw-bold " + (config.demo ? 'btn-demo' : '')}
                onClick={config.demo ? onShowMessage : () => setShowInscriptionModal(true)}
              >
                Inscription
              </button>
            )}
            {checkAccess(canRequest) && (
              <button
                className={"btn btn-primary flex-grow-1 fw-bold " + (config.demo ? 'btn-demo' : '')}
                onClick={config.demo ? onShowMessage : () => setShowRequestModal(true)}
              >
                Demande
              </button>
            )}
          </div>


          <h5 className="d-none d-lg-block mb-4">Sessions</h5>

          <button onClick={() => setSessionsOpened(!sessionsOpened)} className="btn-open-sessions">Sessions</button>

          <div className={(sessionsOpened) ? 'sessions-list opened' : 'sessions-list'}>
            {
              session.map((sess, key) => {
                const place = parseInt(sess?.seat_capacity_max) - parseInt(sess?.contactSessions.length);
                // there is no participants in the data
                return (
                  <div className="formation-session" key={sess.id}>
                    <div className="title">{sess?.location?.town}</div>
                    <div className="mb-1">
                      {(new Date(sess?.dateStart))?.toLocaleDateString('fr', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })}
                    </div>
                    {place <= 0 ? (
                      <div className="d-flex align-items-center gap-2 text-danger">
                        <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="3" cy="3" r="3" fill="currentColor" />
                        </svg>
                        Complet
                      </div>
                    ) : (
                      <div className="d-flex align-items-center gap-2 text-success">
                        <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="3" cy="3" r="3" fill="currentColor" />
                        </svg>
                        {place}{' '}places disponibles
                      </div>
                    )}
                  </div>
                );
              })
            }
          </div>

          {
            // Comme les modales chargent des données, on fait en sorte que les
            //   données soient chargées à l'ouverture de la modale et pas à
            //   l'avance, afin de charger moins de données inutiles.
            showInscriptionModal && <Popupinscrire
            handleModalShow={showInscriptionModal}
            handleModalClose={handleCloseInscriptionModal}
            data={dataformation}
            options={options}
            uri={uri}
          />}
          {
            // Comme les modales chargent des données, on fait en sorte que les
            //   données soient chargées à l'ouverture de la modale et pas à
            //   l'avance, afin de charger moins de données inutiles.
            showRequestModal && <PopupDemande
            handleModalShow={showRequestModal}
            handleModalClose={handleCloseRequestModal}
            data={dataformation}
            options={options}
            uri={uri}
          />}
          <PopupDemo
            show={showMessageModal}
            onHide={onHideMessage}
            type="link"
          />
        </div>
      </div>
    </BaseContainer>
  );
}
