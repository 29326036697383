import { useState } from 'react';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';


export function useAuthUrl( ) {

  const [url, setUrl] = useState("")
  const [loading, setLoading] = useState(false)
  const { authData } = useAuthProvider()!;  

  // Pour être sûr de sécuriser l'envoi du support de cours, il faut inclure
  //   le token dans les headers de la requête. La réponse sera stockée dans un
  //   blob dans la variable url et alimentera les lecteurs PDF, vidéo, audio
  const options = {
    headers: {
      Authorization: `Bearer ${authData.token}`
    }
  }

  /**
   * Insère un en tête de sécurisation et récupère un blob en échange
   * Puis met à jour url
   */
  const setAuthUrl = (contentUrl : string) => {
    if(contentUrl){
      fetch(contentUrl, options)
      .then(response => response.blob())
      .then(blob => {
        setUrl(URL.createObjectURL(blob))
      });
    }
  }

  /**
   * Insère un en tête de sécurisation et récupère un blob en échange
   * Puis ouvre dans un nouvel onglet
   */
  const openAuthUrl = (event : Event, contentUrl : string) => {
    if(contentUrl){

      // En cas de clic sur un lien embarqué dans un formulaire, 
      //   évite les éventuelles soumissions de formulaire.
      event.preventDefault();
      event.stopPropagation();
      setLoading(true);

      fetch(contentUrl, options)
        .then(response => response.blob())
        .then(blob => {
          setLoading(false);
          const pdfWindow = window.open();
          if(pdfWindow){
            pdfWindow.location.href = URL.createObjectURL(blob);
          }
        });
    }
  }

  return {url, setUrl, setAuthUrl, loading, setLoading, openAuthUrl };
}
