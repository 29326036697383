import React, { useState } from 'react';

import { useRouter } from 'leeloo-react/component/hook/useRouter';
import { ColumnType } from 'leeloo-react/model/dataTable/ColumnType';
import { RouterCustomGetFileLink } from '../../router/RouterCustomGetFileLink';
import PopupDemo from '../../popup/PopupDemo';

type Props<T> = ColumnType<T> & {
  resource: string,
  secondResource?: string,
  nameFile: string,
  nameExt: string,
  nameFileFieldLabel: string,
  params?: { },
  disabled?: boolean,
}

/**
 * TODO : factorisation possible : code inspiré de DowloadColumn, mais décorellé du tableau
 */
export function ProfileDownload<T>(props: Props<T>) {
  const router = useRouter();
  const [showModal, setShowModal] = useState(false);
  let { resource } = props;

  if (props && props.row && (props?.row as any)?.type === 'trainings') {
    resource = props.secondResource ? props.secondResource : props.resource;
  }

  if (props.params) {
    resource = router.generatePath(resource, resource, props.params);
  }

  const nameFile = `${props.nameFile}-${`${props.nameFileFieldLabel}` in props.row! && props.row ? (props.row as any)[`${props.nameFileFieldLabel}`] : ''}.${props.nameExt}`;


  const onShowMessage = () => {
    setShowModal(true);
  }

  const onHideMessage = () => {
    setShowModal(false);
  }

  const svgDownloadIcon = () => {
    return <svg width="17" height="16" viewBox="0 0 17 16" fill="none"
                xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.027 8.86a.667.667 0 0 0 0 .947l2 2a.667.667 0 0 0 .946 0l2-2a.67.67 0 1 0-.946-.947l-.86.867V2a.667.667 0 1 0-1.334 0v7.727l-.86-.867a.667.667 0 0 0-.946 0ZM12.5 6h-1.333a.667.667 0 1 0 0 1.333H12.5a.667.667 0 0 1 .667.667v4.667a.667.667 0 0 1-.667.666h-8a.667.667 0 0 1-.667-.666V8a.667.667 0 0 1 .667-.667h1.333a.667.667 0 0 0 0-1.333H4.5a2 2 0 0 0-2 2v4.667a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2Z"
        fill="#006FFF"/>
    </svg>;
  }

  // Utilise un RouterCustomGetFileLink pour avoir une présentation custom mais un téléchargement via GET
  return (
    <>

      {!props.disabled &&
        <RouterCustomGetFileLink
          resource={resource}
          params={{ id: props.row?.id }}
          nameFile={nameFile}
        >
          <a
            className="file-download"
          >
            {props.title}
            {svgDownloadIcon()}
        </a>
      </RouterCustomGetFileLink>}

      {props.disabled && <span>
        <button
          className="btn btn-link file-download file-download-disabled"
          onClick={onShowMessage}
        >
          {props.title}
          {svgDownloadIcon()}
        </button>
      </span>}

      {showModal && <PopupDemo
        show={showModal}
        onHide={onHideMessage}
        type="link"
      >
      </PopupDemo>}

    </>
  );
}

