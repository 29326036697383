import { useState } from 'react';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';


export function useAuthUrlImg( ) {

  const [urlimg, setUrlImg] = useState("")
  const { authData } = useAuthProvider()!;  

  // Pour être sûr de sécuriser l'envoi du support de cours, il faut inclure
  //   le token dans les headers de la requête. La réponse sera stockée dans un
  //   blob dans la variable url et alimentera les lecteurs PDF, vidéo, audio
  const options = {
    headers: {
      Authorization: `Bearer ${authData.token}`
    }
  }

  /**
   * Insère un en tête de sécurisation et récupère un blob en échange
   * Puis met à jour urlimg
   */
  const setAuthUrlImg = (contentUrl : string) => {
    if(contentUrl){
      fetch(contentUrl, options)
      .then(response => response.blob())
      .then(blob => {
        setUrlImg(URL.createObjectURL(blob))
      });
    }
  }

  return {urlimg, setUrlImg, setAuthUrlImg };
}
