import React, { ReactElement, useEffect } from 'react';

import { LoadContent } from 'leeloo-react/component/display/LoadContent';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import { useRouter } from 'leeloo-react/component/hook/useRouter';
import { useWriteReducer } from 'leeloo-react/component/hook/useWriteReducer';
import { ReducerNameEnum } from 'leeloo-react/enum/store/ReducerNameEnum';

import RoleUserEnum from '../../enum/RoleUserEnum';
import { RouteEnum } from '../../enum/RouteEnum';
import { ReducerEnum } from '../../enum/store/ReducerEnum';
import { AuthReducerType } from '../../model/authProvider/AuthReducerType';
import { AgencyReducerType } from '../../model/store/reducers/AgencyReducerType';
import { ConfigReducerType } from '../../model/store/reducers/ConfigReducerType';

/**
 * Simple page appellé après le login
 * Fais une redirection sur la route qui convient selon les role client
 * @returns
 */
export default function LoadedPage(): ReactElement {
  const { goTo } = useRouter();
  const { authData, checkAccess }: {authData: AuthReducerType, checkAccess: (roles: string[]) => boolean} = useAuthProvider()! as any;
  const dispatchAuth = useWriteReducer<AuthReducerType>(ReducerNameEnum.auth);
  const dispatchAgency = useWriteReducer<AgencyReducerType>(ReducerEnum.agency);
  const dispatchConfig = useWriteReducer<ConfigReducerType>(ReducerEnum.config);

  useEffect(() => {
    let redirectRoute: string;
    if (!authData.terms) {
      redirectRoute = RouteEnum.TERMS_VALIDATE;
    } else if (checkAccess([RoleUserEnum.ROLE_SUPER_ADMIN])) {
      dispatchConfig.set('displayFront', false);
      redirectRoute = RouteEnum.ADMIN_DASHBOARD;
    } else if (checkAccess([RoleUserEnum.ROLE_AGENT_IMMOBILIER, RoleUserEnum.ROLE_ASSOCIE])) {
      redirectRoute = RouteEnum.DASHBOARD;
    } else {
      redirectRoute = RouteEnum.SEARCH;
    }

    const paramsRedirectRoute: any = {};

    // On check si l'utilisateur à accès au e-learning sur au moins une de ses agence
    // Version qui vérifie si le collaborateur a au moins une agence avec Elearning == 'Oui'
    //dispatchConfig.set('accessELearning', (authData.agencies.filter((agency) => agency.Elearning === 'Oui')).length > 0);
    // Version ancienne, qui renvoie toujours true
    dispatchConfig.set('accessELearning', !!(authData.agencies.filter((agency) => agency.Elearning === 'Oui')));

    // Permettra de mettre en disabled les fonctionnalités non accesibles en
    //   mode démo
    dispatchConfig.set('demo', authData.demo);

    // on save un shop par défaut
    if (authData.agencies.length > 0) {
      dispatchAgency.setGlobal(authData.agencies[0]);
    }

    // Vide les agences pour ne pas surcharger les cookies puis sauvegarde au redux
    // Les infos sur les agences ont été dispatchées dans le dispatchAgency
    //   donc pas besoin de les stocker deux fois
    authData.agencies = [];
    dispatchAuth.setGlobal({ ...authData });

    // Redirection
    goTo(redirectRoute, paramsRedirectRoute);
  }, []);

  return <LoadContent isLoad />;
}
