import React, { ReactElement, useState } from 'react';

import { Icon } from 'leeloo-react/component/display/Icon';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import { useWriteReducer } from 'leeloo-react/component/hook/useWriteRedux';
import { RouterLink } from 'leeloo-react/component/router/RouterLink';
import { Nav } from 'react-bootstrap';

import { ReactComponent as Envelope } from '../../assets/icons/envelope.svg';
import { ReactComponent as Gh } from '../../assets/icons/Gh.svg';
import RoleUserEnum from '../../enum/RoleUserEnum';
import { RouteEnum } from '../../enum/RouteEnum';
import { ReducerEnum } from '../../enum/store/ReducerEnum';
import { AuthReducerType } from '../../model/authProvider/AuthReducerType';
import { ConfigReducerType } from '../../model/store/reducers/ConfigReducerType';
import { LogoutAsLink } from '../auth/logout/LogoutAsLink';
import { LogoutLink } from '../auth/logout/LogoutLink';
import { useRouter } from 'leeloo-react/component/hook/useRouter';
import { useSelector } from 'react-redux';
import { StateType } from '../../model/store/StateType';

export function Sidebar(): ReactElement {
  const dispatchConfig = useWriteReducer<ConfigReducerType>(ReducerEnum.config);
  // eslint-disable-next-line unused-imports/no-unused-vars
  const [notify, setNotify] = useState(0);
  const { checkAccess, authData }: {checkAccess: (roles: string[]) => boolean, authData:AuthReducerType} = useAuthProvider()! as any;
  const [open, setOpen] = useState<boolean>(false);
  const router = useRouter();
  const config = useSelector((s: StateType) => s.config);

  const subRoutes : { [key:string] :[string]} = {
    [RouteEnum.E_LEARNING] : [RouteEnum.E_LEARNING_DETAIL],
    [RouteEnum.PRESENTIEL] : [RouteEnum.PRESENTIEL_DETAIL]
  }

  /**
   * Gere le maintien de la classe active si on est sur une sous-route
   * @param parentRoute
   */
  function isRouterLinkActive(parentRoute : string){
    return (
      parentRoute in subRoutes &&
      router.currentRouteName &&
      subRoutes[parentRoute].includes(router.currentRouteName)
    ) ? " active" : "";
  }

  return (
    <div className={`sidebar ${open ? 'opened' : ''}`}>
      <RouterLink
        routeName={RouteEnum.PROFILE}
        className="nav_logo"
      >
        <Gh />
      </RouterLink>
      {config.demo && <div className="demo-flag">(demo)</div>}
      <div role="button" className="burger" tabIndex={0} onClick={() => setOpen(!open)} onKeyUp={() => setOpen(!open)}>
        <span />
        <span />
        <span />
      </div>
      <Nav defaultActiveKey={RouteEnum.ADMIN_DASHBOARD} className="main-nav">
        <ul>
          {checkAccess([RoleUserEnum.ROLE_SUPER_ADMIN, RoleUserEnum.ROLE_AGENT_IMMOBILIER, RoleUserEnum.ROLE_ASSOCIE]) && (
            <li>
              <RouterLink routeName={RouteEnum.DASHBOARD} className="nav-link">
                <Icon name="uil uil-estate" />
                <span className="nav_name">Dashboard</span>
              </RouterLink>
            </li>
          )}
          <li>
            <RouterLink
              routeName={RouteEnum.E_LEARNING}
              className={"nav-link" + isRouterLinkActive(RouteEnum.E_LEARNING)}>
              <Icon name="uil uil-desktop" />
              <span className="nav_name">Formations e-learning</span>
            </RouterLink>
          </li>
          <li>
            <RouterLink
              icon="uil uil-book-alt"
              className={"nav-link" + isRouterLinkActive(RouteEnum.PRESENTIEL)}
              routeName={RouteEnum.PRESENTIEL}>
              <span>Formations présentielles</span>
            </RouterLink>
          </li>
          <li>
            <RouterLink icon="uil uil-search" className="nav-link" routeName={RouteEnum.SEARCH}>
              <span>Catalogue de formations</span>
            </RouterLink>
          </li>
          <li>
            <RouterLink icon="uil uil-user" className="nav-link" routeName={RouteEnum.PROFILE}>
              <span>Profil</span>
            </RouterLink>
          </li>
          <li>
            <RouterLink className="nav-link" routeName={RouteEnum.DOCUMENTS}>
              <span className="position-relative">
                <i className="uil uil-envelope" />
                {notify !== 0 && (
                  <span className={`position-absolute top-4 start-100 translate-middle p-1 bg-danger rounded-circle badge ${notify ? 'notify' : ''}`}>
                    {notify}
                    <span className="visually-hidden">Boite de réception</span>
                  </span>
                )}
              </span>
              <span>Notifications</span>
            </RouterLink>
          </li>
        </ul>
        <ul>
          {checkAccess([RoleUserEnum.ROLE_SUPER_ADMIN]) && (
            <li role="presentation" onClick={() => dispatchConfig.set('displayFront', false)}>
              <RouterLink icon="uil uil-user-arrows" className="nav-link" routeName={RouteEnum.ADMIN_DASHBOARD}>
                <span>Administration</span>
              </RouterLink>
            </li>
          )}
          <li>
            { authData.loginAs
              ? <LogoutAsLink />
              : (
                <LogoutLink className="nav-link logout cursor-pointer">
                  <Icon name="uil uil-lock" />
                  <span>Déconnexion</span>
                </LogoutLink>
              )}
          </li>
        </ul>
      </Nav>
    </div>
  );
}
